import { HeadFC, PageProps } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';

import {
  DesignLayout,
  ElementCard,
  Seo,
  VersionCard,
} from '../../../components';
import {
  NeutralButton,
  OutlineButton,
  OutlineNeutralButton,
  SolidButton,
} from '../../../components/design/buttons';
import {
  SeparatorLineStyle,
  SubsectionText,
} from '../../../components/design/cards/style';
import { BodyTextStyle } from '../../../components/design/typography/style';
import { breakpoints } from '../../../theme';

const ButtonsPage: FC<PageProps> = () => {
  return (
    <DesignLayout>
      <VersionCard title="Red button" />

      <ElementCard title="General specs">
        <SubsectionText>Styles</SubsectionText>
        <Container1Style>
          <div>
            <BodyTextStyle fontSize="bodyText1" fontWeight="medium">
              Solid
            </BodyTextStyle>
            <ButtonContainerStyle>
              <SolidButton />
            </ButtonContainerStyle>
          </div>
          <div>
            <BodyTextStyle fontSize="bodyText1" fontWeight="medium">
              Outline
            </BodyTextStyle>
            <ButtonContainerStyle>
              <OutlineButton />
            </ButtonContainerStyle>
          </div>
        </Container1Style>

        <SeparatorLineStyle />

        <Container1Style>
          <div>
            <BodyTextStyle fontSize="bodyText1" fontWeight="medium">
              White: Solid
            </BodyTextStyle>
            <ButtonNeutralContainerStyle>
              <NeutralButton />
            </ButtonNeutralContainerStyle>
          </div>
          <div>
            <BodyTextStyle fontSize="bodyText1" fontWeight="medium">
              White: Outline
            </BodyTextStyle>
            <ButtonNeutralContainerStyle>
              <OutlineNeutralButton />
            </ButtonNeutralContainerStyle>
          </div>
        </Container1Style>

        <SeparatorLineStyle />

        <SubsectionText>Sizes</SubsectionText>
        <Container1Style>
          <div>
            <BodyTextStyle fontSize="bodyText1" fontWeight="medium">
              Large
            </BodyTextStyle>
            <ButtonContainerStyle>
              <SolidButton />
            </ButtonContainerStyle>
          </div>
          <div>
            <BodyTextStyle fontSize="bodyText1" fontWeight="medium">
              Medium
            </BodyTextStyle>
            <ButtonContainerStyle>
              <SolidButton size="medium" />
            </ButtonContainerStyle>
          </div>
          <div>
            <BodyTextStyle fontSize="bodyText1" fontWeight="medium">
              Small
            </BodyTextStyle>
            <ButtonContainerStyle>
              <SolidButton size="small" />
            </ButtonContainerStyle>
          </div>
        </Container1Style>

        <SeparatorLineStyle />

        <SubsectionText>Types</SubsectionText>
        <Container1Style>
          <div>
            <BodyTextStyle fontSize="bodyText1" fontWeight="medium">
              Only text
            </BodyTextStyle>
            <ButtonContainerStyle>
              <SolidButton />
            </ButtonContainerStyle>
          </div>
          <div>
            <BodyTextStyle fontSize="bodyText1" fontWeight="medium">
              Left icon / text
            </BodyTextStyle>
            <ButtonContainerStyle>
              <SolidButton leftArrow />
            </ButtonContainerStyle>
          </div>
          <div>
            <BodyTextStyle fontSize="bodyText1" fontWeight="medium">
              Right icon / text
            </BodyTextStyle>
            <ButtonContainerStyle>
              <SolidButton rightArrow />
            </ButtonContainerStyle>
          </div>
        </Container1Style>

        <SeparatorLineStyle />

        <SubsectionText>States</SubsectionText>
        <Container1Style>
          <div>
            <BodyTextStyle fontSize="bodyText1" fontWeight="medium">
              Enabled / Hover / Pressed
            </BodyTextStyle>
            <ButtonContainerStyle>
              <SolidButton />
            </ButtonContainerStyle>
          </div>
          <div>
            <BodyTextStyle fontSize="bodyText1" fontWeight="medium">
              Disabled
            </BodyTextStyle>
            <ButtonContainerStyle>
              <SolidButton disabled />
            </ButtonContainerStyle>
          </div>
        </Container1Style>
      </ElementCard>
    </DesignLayout>
  );
};

export default ButtonsPage;
export const Head: HeadFC = () => <Seo title="Theme" />;

const ButtonContainerStyle = styled.div`
  align-items: center;
  border: 2px solid ${({ theme }) => theme.colors.neutral[20]};
  border-radius: 0.625rem;
  display: flex;
  height: 11.25rem;
  justify-content: center;
  width: 100%;
`;

const ButtonNeutralContainerStyle = styled(ButtonContainerStyle)`
  background-color: ${({ theme }) => theme.colors.black};
`;

const Container1Style = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  div {
    width: 100%;
  }

  @media screen and (min-width: ${breakpoints.md}) {
    flex-direction: row;
    gap: 1.875rem;
  }
`;
